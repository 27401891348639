import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Table, Tooltip } from '@mantine/core';
import axios from 'axios';
import React, { useCallback, useContext } from 'react';
import { IPedido } from '../../../commons/interfaces/Pedidos';
import useNotifier from '../../../hooks/useNotifier';
import GlobalContext from '../../providers/GlobalContext';
import { getButtonInvoiceStyles } from '../../../commons/utils/ActionButtonsStyleHelper';

interface ActionButtonInvoiceProps {
    request: IPedido;
}

const ActionButtonInvoice: React.FC<ActionButtonInvoiceProps> = (props) => {
    const {globalContext} = useContext(GlobalContext);
    const { processAxiosError } = useNotifier();

    const handleInvoiceEmit = useCallback(() => {
        const body = {
            pedido: props.request.idPedido,
            tkmov: props.request.tkmov,
            empresa: props.request.idEmpresa
        }
        const redirectParams = `tkmov=${props.request.tkmov}&id_empresa=${props.request.idEmpresa}&id_pedido=${props.request.idPedido}&redirectFrom=${globalContext.parentResourceName}`;
        axios({
            url: `${document.referrer}${globalContext.parentContext}/invoices?action=pre-fact-validation`,
            method: 'post',
            data: body,
            withCredentials: true
        }).then((response) => {
            const url = `${document.referrer}${globalContext.parentContext}/factura.jsp?opc_anticipo=N&${redirectParams}`;
            window.parent.location.href = url;
        }).catch((error) => {
            processAxiosError(error);
        });
    }, [processAxiosError, globalContext.parentContext, globalContext.parentResourceName, props.request]);

    return (
        <Table.Td pr={3} pl={3}>
            <Tooltip label="Generar facturas">
                <ActionIcon 
                variant="light" 
                size="sm" 
                style={getButtonInvoiceStyles(props.request)} 
                onClick={handleInvoiceEmit} 
                aria-label="Generar facturas">
                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                </ActionIcon>
            </Tooltip>
        </Table.Td>
    );
};

export default ActionButtonInvoice;